// currencyFormat(100000) => £100,000
export const currencyFormat = (
  v: number,
  noCurrency = false,
  minDigits = 0,
  maxDigits = 0,
): string => {
  const formatted = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: minDigits,
    maximumFractionDigits: maxDigits,
  }).format(v)

  return noCurrency ? formatted : `£${formatted}`
}

// percentageFormat(0.75) => 0.75%
export const percentageFormat = (v: number): string => `${v}%`

export const formatThousands = (n: number) => {
  const s = n.toString()
  const [int, dec] = s.split('.')
  const intFormatted = int.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return dec ? `${intFormatted}.${dec}` : intFormatted
}
