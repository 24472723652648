import { updateConsents, setConfig } from '../components/helpers/consent'

const platinumEmailCapture = document.querySelectorAll('[data-email-capture]')

const errorId = 'EmailCapture__box__form__formError'

let timer: any
const waitTime = 500

if (platinumEmailCapture && platinumEmailCapture.length > 0) {
  setConfig({
    apiPath: 'https://www.money.co.uk/consent',
    clientId: 'fs-core',
    brand: 'money',
  })
}

platinumEmailCapture?.forEach((e) => {
  const platinumEmailCaptureFormButton = e.querySelector('[data-email-capture-submit]')!
  const platinumEmailCaptureInput = e.querySelector(
    '[data-email-capture-input]',
  )! as HTMLInputElement

  platinumEmailCaptureInput.addEventListener('keyup', () => {
    timer && clearTimeout(timer)

    timer = setTimeout(() => {
      validateForm(platinumEmailCaptureInput, platinumEmailCaptureFormButton)
    }, waitTime)
  })
  platinumEmailCaptureInput.addEventListener('blur', () => {
    validateForm(platinumEmailCaptureInput, platinumEmailCaptureFormButton)
  })

  platinumEmailCaptureFormButton.addEventListener('click', async () => {
    try {
      const consentName =
        e.querySelector('[data-email-capture-consent]')?.getAttribute('data-consent-name') || ''
      const consentSource =
        e.querySelector('[data-email-capture-consent]')?.getAttribute('data-consent-source') || ''
      const form = e.querySelector('[data-email-capture-box]')
      const success = e.querySelector('[data-email-capture-success]')
      await submitForm(consentName, consentSource, platinumEmailCaptureInput, form, success)
    } catch (error) {
      errorMessage('An error occurred', platinumEmailCaptureInput)
    }
  })
})

const errorMessage = (message: string, input: Element) => {
  const error = document.getElementById(errorId)
  if (!error) {
    const error = document.createElement('div')
    error.id = errorId
    error.classList.add(errorId)
    error.textContent = message
    input.after(error)
  }
}

const submitForm = async (
  consentName: string,
  consentSource: string,
  input: HTMLInputElement,
  form: Element | null,
  success: Element | null,
) => {
  const email = input.value
  const secondaryConsentInput = form?.querySelector(
    '[data-email-capture-secondary-consent]',
  ) as HTMLInputElement | null
  const consents = [consentName]
  if (secondaryConsentInput?.checked) {
    const secondaryConsentName = secondaryConsentInput.getAttribute(
      'data-email-capture-secondary-consent',
    )
    secondaryConsentName && consents.push(secondaryConsentName)
  }
  await submitConsent(email, consents, consentSource)
  if (form?.classList.contains('EmailCapturePrimary__box')) {
    form?.classList.remove('EmailCapturePrimary__box')
  }
  form?.classList.add('EmailCapture__box__hidden')
  success?.classList.add('EmailCapture__success__visible')
}

const submitConsent = async (email: string, consents: string[], consentSource: string) => {
  return await updateConsents({
    email,
    consents: consents.map((c) => {
      return {
        status: true,
        name: c,
        source: consentSource,
        sourceUrl: window.location.href,
      }
    }),
  })
}

const validateForm = (input: HTMLInputElement, submitButton: Element) => {
  const erroredInput = 'EmailCapture__box__form__input__error'
  const email = input.value

  if (email) {
    if (!validateEmail(email)) {
      addOrRemoveError(errorId, erroredInput, 'add', input)
      submitButton?.setAttribute('disabled', 'true')
    } else {
      addOrRemoveError(errorId, erroredInput, 'remove', input)
      submitButton?.removeAttribute('disabled')
    }
  } else {
    addOrRemoveError(errorId, erroredInput, 'remove', input)
  }
}

const validateEmail = (email: string) => {
  return String(email).toLowerCase().match(/@/)
}

const addOrRemoveError = (errId: string, input: string, action: string, inputElement: Element) => {
  if (action === 'remove') {
    const error = document.getElementById(errId)
    error?.remove()
    inputElement?.classList.remove(input)
  } else {
    errorMessage('Please input a valid email', inputElement)
    inputElement?.classList.add(input)
  }
}
